.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.display-container {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.books-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.no-text {
  font-family: 'Inter' !important;
  color: var(--dark-blue) !important;
  font-weight: 300 !important;
  margin-bottom: 50px !important;
}

@media screen and (min-width: 960px) and (max-width: 1380px) {
  .books-container {
    width: 880px;
    justify-content: flex-start;
  }
}
