.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  /* margin-bottom: 60px; */
  width: 100%;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--dark-blue) !important;
}

.text {
  color: var(--dark-blue) !important;
  font-family: 'Inter' !important;
  font-weight: 300 !important;
}

.contact-box {
  width: 100%;
  background-color: var(--white);
  border-radius: 50px;
  box-shadow: 0px 4px 20px 10px rgba(170, 170, 170, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}

.mini-box-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mini-box-container-last {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mini-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.mini-box-figure {
  margin-right: 15px;
}

.mini-box-image {
  max-width: 100%;
  max-height: 100%;
}

.mini-box-title {
  font-family: 'Inter' !important;
  font-weight: 500 !important;
  color: var(--text-blue) !important;
}

.mini-box-text {
  font-family: 'Inter' !important;
  font-weight: 300 !important;
  color: var(--text-blue) !important;
}

.gradient-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 100%;
  background-image: linear-gradient(#FA91B4 0%, #A3D4F5 100%);
}