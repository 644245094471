.container {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.figure {
  width: 50px;
  height: 50px;
}

.image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.title {
  font-family: 'KyviType Sans Regular, sans serif' !important;
  color: var(--dark-blue) !important;
}

.text {
  color: var(--dark-blue) !important;
  font-family: 'Inter' !important;
  font-weight: 300 !important;
}
