.container {
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
}

.container:hover .text {
  opacity: 1 !important;
}

.text {
  font-family: 'Inter' !important;
  color: var(--dark-blue);
}
