.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
}

.figure {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.image {
  height: 60%;
}

.list-container {
  display: flex;
  align-items: center;
  height: 100%;
}
