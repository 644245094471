.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.figure-logo {
  position: absolute;
  z-index: -1;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-container {
  margin-bottom: 45px;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--dark-blue) !important;
}

.text {
  color: var(--dark-blue) !important;
  font-family: 'Inter' !important;
  font-weight: 300 !important;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
