.container {
  background-color: var(--light-blue-o);
}

.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: 0 auto;
}

.footer {
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 12px;
  overflow: hidden;
}
