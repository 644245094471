.carousel-container {
  height: auto;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  border-radius: 25px;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--dark-blue) !important;
}

.specialtie-title {
  font-family: 'Inter' !important;
  color: var(--text-blue) !important;
}