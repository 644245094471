.container {
    width: 30%;
    min-width: 380px;
    height: 350px;
    background-color: transparent;
    border-radius: 40px;
    margin: 0px 20px 40px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .mini-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-gray);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

  .figure {
    padding: 60px 0px;
  }
  
  .text-container {
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 25px;
  }
  
  .title {
    font-size: 22px !important;
    font-weight: 600 !important;
    font-family: 'Inter' !important;
    color: var(--dark-blue) !important;
    margin-bottom: 10px !important;
  }
  
  .description {
    font-size: 16px !important;
    font-family: 'Inter' !important;
    color: var(--dark-blue) !important;
  }
  