/* Fonts */
@font-face {
  font-family: 'KyviType Sans Light, sans serif';
  src: url('assets/fonts/KyivTypeSansLight.otf');
}
@font-face {
  font-family: 'KyviType Sans Regular, sans serif';
  src: url('assets/fonts/KyivTypeSansRegular.otf');
}
@font-face {
  font-family: 'KyviType Sans Medium, sans serif';
  src: url('assets/fonts/KyivTypeSansMedium.otf');
}
@font-face {
  font-family: 'KyviType Sans Bold, sans serif';
  src: url('assets/fonts/KyivTypeSansBold.otf');
}
/* Fonts */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'KyviType Sans Regular, sans serif';
  letter-spacing: -0.01em;
  line-height: 150%;
}
#root {
  font-family: 'KyviType Sans Regular, sans serif';
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 8px;
  margin: 0 10px;
}
:hover::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue);
  transition: all 0.8s;
}
/* End Scrollbar */
