.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--white);
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--dark-blue) !important;
  margin-bottom: 48px !important;
}

.text {
  color: var(--dark-blue) !important;
  font-family: 'Inter' !important;
  font-weight: 300 !important;
  margin-bottom: 50px !important;
}

.figure-logo {
  width: 70px;
}

.image {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

.image-logo {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.gradient-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 100%;
  background-image: linear-gradient(90deg, #fa91b4 0%, #a3d4f5 100%);
}
