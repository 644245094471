.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.box {
  padding: 50px;
  background-image: url('assets/images/presentation-image.svg');
  width: 100%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--white) !important;
}

.subtitle {
  color: var(--subtitle-blue) !important;
  font-family: 'Inter' !important;
}

.logo {
  position: absolute;
  width: 56px;
  height: 49px;
  top: 90px;
}

.image {
  width: 100%;
  height: 100%;
}

.float-image {
  position: absolute;
  top: 60px;
  right: 60px;
  height: 312px;
  /* width: 900px; */
}