.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.nav-test {
  height: 100px;
  width: 100%;
  background-color: var(--white);
}

.content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.figure {
  position: absolute;
  z-index: -1;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 30px;
}

.title {
  font-family: 'KyviType Sans Light, sans serif' !important;
  color: var(--dark-blue) !important;
}

.text {
  font-family: 'Inter' !important;
  color: var(--subtitle-blue) !important;
  font-weight: 300 !important;
  letter-spacing: 0.88em !important;
}
