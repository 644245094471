.container {
  padding: 10px 50px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}

.figure {
  width: 270px;
  height: auto;
  /* margin-bottom: 50px;
  margin-right: 20px; */
}

.figure-logo {
  position: absolute;
}

.image {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
