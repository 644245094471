.border-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 40px;
    margin-right: 25px;
    cursor: pointer;
}

.container {
    padding: 12px 32px;
    background-color: var(--white);
    border-radius: 40px;
}

.text {
  font-family: 'Inter' !important;
  color: var(--text-blue) !important;
}