.container {
  width: 30%;
  min-width: 380px;
  /* height: 250px; */
  background-color: transparent;
  border-radius: 40px;
  margin: 0px 20px 40px 20px;
  display: flex;
  align-items: center;
}

.mini-container {
  width: 40%;
  height: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-right: 20px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 30px;
}

.text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: 'Inter' !important;
  color: var(--dark-blue) !important;
  margin-bottom: 20px !important;
  min-height: 66px;
}

.description {
  font-size: 16px !important;
  font-family: 'Inter' !important;
  color: var(--dark-blue) !important;
  margin-bottom: 20px !important;
  min-height: 72px;
}
