:root {
  /* Regular Palette */
  --black: #000000;
  --white: #ffffff;
  --dark-pink: #fa91b4;
  --light-pink: #f1def7;
  --dark-blue: #006da8;
  --medium-blue: #64a1f7;
  --light-blue: #a3d4f5;
  --text-blue: #004B74;
  --subtitle-blue: #64A1F7;
  --light-gray: #EBF1F4;
  --text-blue: #648A9E;
  --background-blue: #F6FCFF;
  /* Light Pallette */
  --light-blue-o: #a3d4f538;
}
